
import { ApiResult } from "./lib/send"

export async function submit_feedback(
	feedback: string,
	bug: boolean,
	feature: boolean,
	general: boolean,
	): Promise<ApiResult<any>>
{
	console.log(bug, feature, general);
	console.log(feedback);
	if (feedback.length===0) {
		return {success: false, reason: "Please provide feedback."};
	}
	return {success: true, result: {}};
}
