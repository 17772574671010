import React, { useState } from "react"

import { submit_feedback } from "../../services/account"


const defaultFormData = {
  bug: '',
  feature: '',
  general: '',
  feedback: '',
};

const FeedbackForm = () => {

  const [state, setState] = useState({
    formData: defaultFormData,
    rejectReason: '',
    success: false,
  });
  const update = (event) => {
    if (["bug", "feature", "general"].includes(event.target.name)) {
			event.target.value = event.target.checked ? "on" : "";
		}
		setState({
			formData: {
				...state.formData,
				[event.target.name]: event.target.value,
      },
      rejectReason: '', // always reset when form changes
      success: false, // always reset when form changes
		});
  };
  const submit = async (event) => {
    console.log("submit");
		event.preventDefault();
    let result = await submit_feedback(
      state.formData.feedback,
      state.formData.bug==="on",
      state.formData.feature==="on",
      state.formData.general==="on"
      );
		if (result.success) {
      setState({
        ...state,
        formData: defaultFormData,
        success: true,
      });
		} else {
			setState({
        ...state,
				rejectReason: (result as {reason: string}).reason,
        success: false,
			});
		}
	};

  return (
    <form id="feedbackForm">
      <div className="form-horizontal">
        <div className="form-group">
          <div className="col-md-12">
            <div className="checkbox">
              <input
                type="checkbox"
                name="bug"
                onChange={update}
                style={{marginLeft:"0px"}}
              />
              &nbsp;
              <label htmlFor="bugFeedback">Bug Report</label>
            </div>
            <div className="checkbox">
              <input
                type="checkbox"
                name="feature"
                onChange={update}
                style={{marginLeft:"0px"}}
              />
              &nbsp;
              <label htmlFor="featureFeedback">Feature Request</label>
            </div>
            <div className="checkbox">
              <input
                type="checkbox"
                name="feedbackType"
                onChange={update}
                style={{marginLeft:"0px"}}
              />
              &nbsp;
              <label htmlFor="generalFeedback">General Feedback</label>
            </div>
            <div className="errorBlock"></div>
          </div>
        </div>
        <div className="form-group">
          <div className="col-md-6">
            <textarea
              className="form-control"
              id="feedbackComment"
              placeholder="Feedback"
              required={true}
              name="feedback"
              value={state.formData.feedback}
              onChange={update}
            ></textarea>
          </div>
        </div>
        <div className="form-group">
          <div className="col-md-6">
            {/*<input className="btn btn-success" id="feedbackSubmit" type="submit" value="Submit Feedback" onClick={submit}/>*/}
            <button className="btn btn-success" type="submit" onClick={submit}>
              Submit Feedback
            </button>
          </div>
        </div>
      </div>
      { state.success &&
        <h3 style={{color:"green"}}>We received your feedback. Thank you!</h3>
      }
      { state.rejectReason.length>0 &&
        <h3 style={{color:"red"}}>{state.rejectReason}</h3>
      }
    </form>
  );
};

export default () => (
  <div id="inner">
    {/*<h2>Thank you for using Scratchwork!</h2>*/}
    <h4>Please help us improve Scratchwork. We appreciate your feedback!</h4>
    <FeedbackForm />
    </div>
);